
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueMask from 'v-mask'
import JsonExcel from 'vue-json-excel'
Vue.config.productionTip = false

Vue.component('downloadExcel', JsonExcel)
Vue.use(VueMask);


router.beforeEach((to, from, next) => {

  if(to.path == '/adm/adm/'){
    router.push('/adm/');
  }
  

  if(to.path == '/adm/login'){
    router.push('/login');
  }
  
  if(to.path != '/login' && to.path != '/lecuponfale' && to.path != '/lecuponwhats' && to.path != '/lecuponsugestao'){
      const user = JSON.parse(sessionStorage.getItem('user'))

      if(user == null){
        router.push('login');
      }else if(to.path == '/'){
        router.push('adm/');
      } 
      
  
  }


      next()
}),


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
