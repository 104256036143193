import axios from 'axios'
//const url = 'http://localhost:8093/api/'
const url = 'https://maestrix.grupoparque.com.br:8443/apiprevida/api/'

export const http = axios.create({
     baseURL: url, 
})

http.defaults.timeout = 3800;

/* Override timeout for this request as it's known to take a long time
http.get('/longRequest', {
  timeout: 5000
});
*/

http.interceptors.response.use(function (response) {
     return response;
   }, function (error) {
     /*
     if(error.response.data.status === 401){
          alert('xxxxxx')
          //this.$store.dispatch('auth/logout');
          //this.$router.push('/');
     }
     */
     return Promise.reject(error);
   });


export default {
     url,
}