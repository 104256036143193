import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  //mode: 'history',
  base: process.env.BASE_URL,
  routes: [

   
    { 
      path: '*', 
      //component: NotFoundComponent,
      component: () => import('@/views/dashboard/adm/Page404'),
      name: 'NotFound'
    },
    
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: 'adm/',
          component: () => import('@/views/dashboard/adm/Dashboard'),
        },
        
        // Pages
        {
          name: 'Usuário',
          path: 'adm/usuario',
          component: () => import('@/views/dashboard/adm/Usuario'),
        },

        {
          name: 'Cupom',
          path: 'adm/cupom',
          component: () => import('@/views/dashboard/adm/Cupom'),
        },

        {
          name: 'Carnê',
          path: 'adm/digital',
          component: () => import('@/views/dashboard/adm/Digital'),
        },

        {
          name: 'Aprovadas',
          path: 'adm/aprovadas',
          component: () => import('@/views/dashboard/adm/Aprovadas'),
        },

        {
          name: 'Cancelados',
          path: 'adm/cancelados',
          component: () => import('@/views/dashboard/adm/Cancelados'),
        },
        {
          name: 'Vendas',
          path: 'adm/vendas',
          component: () => import('@/views/dashboard/adm/Vendas'),
        },

        {
          name: 'Register',
          path: 'adm/register',
          component: () => import('@/views/dashboard/adm/Register'),
        },

        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },

        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },

        {
          name: 'Promotores',
          path: 'adm/promotores',
          component: () => import('@/views/dashboard/adm/Promotores'),
        },
        {
          name: 'Controletj',
          path: 'adm/controletj',
          component: () => import('@/views/dashboard/adm/Controletj'),
        },

        {
          name: 'Tem',
          path: 'adm/tem',
          component: () => import('@/views/dashboard/adm/Tem'),
        },

        {
          name: 'Listagem Tem',
          path: 'adm/temlote',
          component: () => import('@/views/dashboard/adm/TemLote'),
        },

        {
          name: 'Premiados',
          path: 'adm/premiado',
          component: () => import('@/views/dashboard/adm/Premiado'),
        },

        {
          name: 'Eventos',
          path: 'adm/eventos',
          component: () => import('@/views/dashboard/adm/Evento'),
        },

        {
          name: 'Atividade',
          path: 'adm/atividade',
          component: () => import('@/views/dashboard/adm/Atividade'),
        },

     

      ],
      
      
    },
    {
      path: '/login',
      component: () => import('@/views/dashboard/Login'),
    },

    {
      path: '/lecuponfale',
      component: () => import('@/views/dashboard/LecuponFale'),
    },

    {
      path: '/lecuponwhats',
      component: () => import('@/views/dashboard/LecuponWhats'),
    },

    {
      path: '/lecuponsugestao',
      component: () => import('@/views/dashboard/LecuponSugestao'),
    },

    {
      path: '/restrito/atualizacao',
      component: () => import('@/views/restrito/Atualizacao'),
    },

  ],
})
